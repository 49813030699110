import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.min.css';
import './style.css';
import icon1 from './img/i1.png'
import icon2 from './img/i2.png'
import icon3 from './img/i3.png'
import icon4 from './img/i4.png'
import mapa from './img/mapa.png'
import c1 from './img/c1.png'
import c2 from './img/c2.png'
import c3 from './img/c3.png'
import c4 from './img/c4.png'
import c5 from './img/c5.png'
import c6 from './img/c6.png'
import c7 from './img/c7.png'
import c8 from './img/c8.png'
import c9 from './img/c9.png'
import bull from './img/bull.png'
import bcc from './img/bcc.png'
import ccc from './img/ccc.png'
import tron from './img/tron-logo.png'
import tg from './img/tg.png'
import ModalImage from 'react-modal-image';


import g1m from './img/video/v1.mp4'
import g1mini from './img/video/v1m.mp4'
import g2m from './img/video/v2.mp4'
import g2mini from './img/video/v2m.mp4'
import g3m from './img/video/v3.mp4'
import g3mini from './img/video/v3m.mp4'
import g4m from './img/video/v4.mp4'
import g4mini from './img/video/v4m.mp4'
import g5m from './img/video/v5.mp4'
import g5mini from './img/video/v5m.mp4'

import g1 from './img/g1.png'
import g2 from './img/g2.png'
import g3 from './img/g3.png'
import g4 from './img/g4.png'



function Features() {

  const [isOpen, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const openModal = (video) => {
    setCurrentVideo(video);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const VideoThumbnail = ({ thumbnail, video, onClick }) => (
    <div
      className="video-thumbnail"
      style={{ position: 'relative', cursor: 'pointer', width: '494px', height: '278px' }}
      onClick={() => onClick(video)} // Przekazuje prop video do funkcji onClick
    >
      <ReactPlayer
        url={thumbnail}
        playing={true}
        controls={false}
        muted
        loop
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );

  return (
    <>
      <div id='Features' className="features">
        <div>
          <h2 data-aos="fade-up">Features</h2>
          <h3 data-aos="fade-up" style={{marginTop:'10px'}}>Cross-Platform Availability:</h3>
          <div className='features-row logos'>
            <a href='https://chickenwars.io/chickenwarslauncher.exe' target='_blank' rel="noreferrer"><img data-aos="fade-up" data-aos-delay="300" src={icon1} alt=''/></a>
            <a href='macos' style={{opacity: '.25'}}><img data-aos="fade-up" data-aos-delay="400" src={icon2} alt=''/></a>
            <a href='ios' style={{opacity: '.25'}}><img data-aos="fade-up" data-aos-delay="500" src={icon3} alt=''/></a>
            <a href='android' style={{opacity: '.25'}}><img data-aos="fade-up" data-aos-delay="600" src={icon4} alt=''/></a>
          </div>
        </div>
        <div>
          <h3 data-aos="fade-up">Engaging Gameplay:</h3>
          <p data-aos="fade-up">The side-scrolled 3D shooter made with the Unity engine and integrated with the TRON blockchain.</p>
          <div className='galery'>
            <VideoThumbnail
              thumbnail={g1mini}
              video={g1m}
              onClick={() => openModal(g1m)}
            />
            <VideoThumbnail
              thumbnail={g5mini}
              video={g5m}
              onClick={() => openModal(g5m)}
            />
            <VideoThumbnail
              thumbnail={g3mini}
              video={g3m}
              onClick={() => openModal(g3m)}
            />
            <VideoThumbnail
              thumbnail={g4mini}
              video={g4m}
              onClick={() => openModal(g4m)}
          />
          </div>
        </div>
        <div className='trailer'>
          <h3 data-aos="fade-up">Trailer:</h3>
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              url='https://www.youtube.com/watch?v=x2fO5kO3nOk&ab_channel=CoconutChicken'
            />
          </div>
        </div>
      </div>

      <div className='section-2'>
        <div>
          <h3 data-aos="fade-up" style={{marginTop: 0}}>Global Servers:</h3>
          <p data-aos="fade-up">Experience low-latency gaming with worldwide servers.</p>
          <div style={{marginTop: '50px'}}>
            <img className='mapa' data-aos="fade-up" src={mapa} alt=''/>
          </div>          
        </div>
        <div>
          <h3 data-aos="fade-up" style={{marginBottom:'-30px', marginTop: '120px'}}>Unique Characters:</h3>
          <div className='characters-row'>
            <div className='item' data-aos="fade-up">
              <img className='hero' src={c1} alt=''/>
              <b>TRON BULL</b>
              <span>$TBULL</span>
              <img className='clogo' src={bull} alt='' width={68}/>
            </div>
            <div className='item main6' data-aos="fade-up">
              <img className='hero' src={c2} alt=''/>
              <b>CHICKENSUN</b>
              <span>$BCC</span>
              <img className='clogo' src={bcc} alt='' width={68}/>                            
            </div>
            <div className='item main1' data-aos="fade-up">
              <img className='hero' src={c3} alt=''/>
              <b>HUNTER</b>
              <span>$TRX</span>
              <img className='clogo' src={tron} alt='' width={72}/>                            
            </div>
            <div className='item main2' data-aos="fade-up">
              <img className='hero' src={c4} alt=''/>
              <b>Justin Sun</b>
              <span>$TRX</span>
              <img className='clogo' src={tron} alt='' width={72}/>                            
            </div>
            <div className='item main3' data-aos="fade-up">
              <img className='hero' src={c5} alt=''/>
              <b>TRON GUY</b>
              <span>$TRNGUY</span>
              <img className='clogo' src={tg} alt='' width={74}/>                            
            </div>
            <div className='item main4' data-aos="fade-up">
              <img className='hero' src={c6} alt=''/>
              <b>TRAVIS</b>
              <span>$CCC</span>
              <img className='clogo' src={ccc} alt='' width={68}/>                            
            </div>
            <div className='item main5' data-aos="fade-up">
              <img className='hero' src={c7} alt=''/>
              <b>BLITZ</b>
              <span>$CCC</span>
              <img className='clogo' src={ccc} alt='' width={68}/>                            
            </div>
            <div className='item' data-aos="fade-up">
              <img className='hero' src={c9} alt=''/>
              <b>MEOW</b>
              <span>$CCC</span>
              <img className='clogo' src={ccc} alt='' width={68}/>                            
            </div>
            <div className='item' data-aos="fade-up">
              <img className='hero' src={c8} alt=''/>
              <b>SPARK</b>
              <span>$CCC</span>
              <img className='clogo' src={ccc} alt='' width={68}/>                            
            </div>
          </div>
        </div>
      </div>  
      <ModalVideo
        channel='custom'
        isOpen={isOpen}
        url={currentVideo}
        onClose={closeModal}
      /> 
    </>

  );
}

export default Features;
