import React, { useEffect} from 'react';
import './App.css';
import Header from './section/header/Header';
import Features from './section/features/Features';
import Weekly from './section/weekly/Weekly';
import Blockchain from './section/blockchain/Blockchain';
import Follow from './section/follow/Follow';
import AOS from 'aos';

function App() {

  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      offset: 120, // offset (in px) from the original trigger point
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom',
    });
  }, []);

  return (
    <div className="app">
      <Header/>
      <Features/>
      <Weekly/>
      <Follow/>
    </div>
  );
}

export default App;
