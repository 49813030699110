import './style.css';
import graph from './img/graph.png'
import graph2 from './img/graph2.png'

function Weekly() {
  return (
    <>
      <div id='Weekly' className="weekly">
        <h2 data-aos="fade-up" style={{marginBottom:"30px"}}>Weekly Tournament Structure</h2>
        <p data-aos="fade-up" >
          <b>Weekly Pools</b>
          Funded by player passes and sponsors, displayed in $CCC and USDT.
        </p>
        <p data-aos="fade-up" >
          <b>Entry Requirements</b>
          Purchase a weekly pass for $10 in $CCC.
        </p>
        <p data-aos="fade-up" >
          <b>Match Frequency</b>
          Up to two Ranked Deathmatch (VIP) games per day.
        </p>
        <p data-aos="fade-up" >
          <b>Leaderboards and Rewards</b>
          Separate leaderboards for Ranked and Free matches, with top players receiving rewards every Sunday.
        </p>

        <div data-aos="fade-up" >
          <img className='graph-desktop' src={graph} alt=''/>
          <img className='graph-mobile' src={graph2} alt=''/>
          <p style={{fontSize: '18px'}}>Weekly Reward Pool</p>
        </div>

        <div id='Blockchain' className="blockchain" style={{marginTop:'60px'}}>
          <h2 data-aos="fade-up" >Blockchain Integration</h2>
          <h3 data-aos="fade-up" >Smart Contract (MainNet) Links:</h3>
          <p data-aos="fade-up" >
            <b>Chicken Wars:</b>
            <span>
              <a href='https://tronscan.org/#/contract/TMcdzxUZqxUTKxQaZxXmz4j6DqY39x1sv5/code' target='_blank' rel="noreferrer">TRONSCAN | 
              TRON BlockChain Explorer</a>
            </span>  
            Handles shop functionality that allows the purchasing of characters and links to leaderboard contracts.
          </p>
          <p data-aos="fade-up" >
            <b>Unranked Leaderboard Manager: </b>
            <span>
              <a href='https://tronscan.org/#/contract/TCvU4m8cV43nyFh2Byd7RCMRUyarpnRDKd/code' target='_blank' rel="noreferrer">TRONSCAN | 
              TRON BlockChain Explorer</a>
            </span>  
            Handles Free Deathmatch leaderboard and sorts users based on their scores. Allows for registering game results at the end of the match.
          </p>
          <p data-aos="fade-up" >
            <b>Ranked Leaderboard Manager:</b>
            <span>
              <a href='https://tronscan.org/#/address/TUBGtr8Y16eQTJ5MwoPaLuVwCxe86XJXHd' target='_blank' rel="noreferrer">TRONSCAN | 
              TRON BlockChain Explorer</a>
            </span>         
            Handles Ranked Deathmatch leaderboard as well as purchasing ranked weekly passes and tracking daily plays made by users. It holds information about each ranked week separately and their leaderboards. It is used to reward players at the end of the week.
          </p>
        </div>


      </div>
    
    </>

  );
}

export default Weekly;
