import './style.css';
import tw from '../header/img/tw.png';
import tele from '../header/img/tele.png';
import ccc from '../features/img/ccc.png'

function Follow() {
  return (
    <div className="follow">
      <h2 data-aos="fade-up" style={{marginBottom:"30px"}}>Follow</h2>
      <div className='social'  data-aos="fade-up">
        <ul>
          <li>
            <a href='tweeter'>
              <img src={tw} alt=''/>
            </a>
          </li>
          <li>
            <a href='tweeter'>
              <img src={tele} alt=''/>
            </a>
          </li>
        </ul>
      </div>
      <p  data-aos="fade-up">
        For further inquiries and support, please contact us at  
        <a href='info@chickenwars.io'>info@chickenwars.io</a>
      </p>
      <img src={ccc} alt='' width={68}/>
    </div>
  );
}

export default Follow;
